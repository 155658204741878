import React from "react";
import logo from "./BNF-logo-filter.svg";
import './loadingSplash.css'
function SplashFullScreen() {
    const splashRef = React.createRef();
    // const hideSplash = () => {
        // Hide splash screen
        setTimeout(() => {
            splashRef.current.style.display = "none";
        }, 2200);
    // }
    return (
        <div ref={splashRef} style={{position:'absolute',
            width:'100vw',
            height:'100vh',
            background:'#673ab7',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            // filter: 'brightness(0.5)',
            zIndex:99,
        }}>
            <img src={logo} className="logo-splash" alt="logo" />
        </div>
    )
}
export default SplashFullScreen;