import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {RouterProvider, createBrowserRouter} from "react-router-dom";

const router = createBrowserRouter([
    {
        path: "/",
        element: <App />,
        children: [

            // {
            //     path: "events/:id",
            //     element: <Test />,
            // },

        ],
    },
]);
// const router = createBrowserRouter(
//     createRoutesFromElements(
//         <Route path="/" element={<App />}>
//             <Route path="/api/test" element={<Test />} />
//             {/* ... etc. */}
//         </Route>
//     )
// );

ReactDOM.createRoot(document.getElementById("root")).render(
        <RouterProvider router={router} />
    // <Router>
    //         <Route  path="/" component={App}/>
    //         <Route path="/team" component={Test}/>
    // </Router>
);
// ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
