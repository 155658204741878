import React from 'react';
import logo from './BNF-final.svg';
import './App.css';
import SplashFullScreen from "./LoadingSplash";
import Option2 from './options/play_earn.png';
import Option3 from './options/swap_send_buy_sell.png';
import SafeData from './options/safeData.png';
import useIsMobile from "./utils/isMobile";
function App() {
    React.useEffect(() => {
        document.title = 'BNF3 Wallet';
    }, []);
  return (
      <>
        <SplashFullScreen/>
        <div >
          <header className="header">
            <img src={logo} className="App-logo" alt="logo" />

              <div className={'header-menu'}>
                <a
                  className="header-link"
                  href="#features"
                >
                  Features
                </a>
                  <a
                  className="header-link"
                  href="#contact"
                >
                  Contact
                </a>
                  <a className={'header-link'} href={"https://discord.gg/EaaX4WjMzm"} target={"_blank"} title={"discord"}>
                      <img src={"https://img.icons8.com/color/48/000000/discord-logo.png"} alt={"discord"} style={{width:"30px"}}/>
                  </a>
              </div>
          </header>

            <SegmentPlayWin link={"#features"}/>
            <SegmentSwapSellBuy link={"#features"}/>
            <SegmentOwnYourData link={"#features"}/>
            <SegmentFooter/>
        </div>
      </>
  );
}

const SegmentPlayWin = () => {
    return (
        <div className={'segment'} id={'features'}>
            <div className={'segment-content'}>
                <h1 className={'segment-title'}>Play & Earn!</h1>
                <p className={'segment-description'}>
                    "Play & Earn" is a innovative concept within the realm of wallet apps, designed to revolutionize the way users interact within the <span className={"trademark"}>BNF3 Wallet</span>.
                    Through competitions, leaderboards, and challenges, users can further enhance their earning potential and enjoy friendly competition with others. <br/>
                    ERC-20 Coins are issued to users based on their performance in these competitions, and can be used to purchase in-app items or exchanged for other cryptocurrencies.* <br/>

                    <br/><br/>
                    <span className={'l-bold '}><a href={'#contact'} className="header-link"> Contact Us to promote your token or project on our platform through fun and games.</a></span>
                    <br/><br/>
                   <span className={"footprint"}>
                    *We operate fully non-custodian, you own all your assets and can transfer them to any wallet at any time.<br/>
                    *We do not offer gambling or betting services, all competitions are skill-based and do not involve chance. You game and we send you tokens based on your score.<br/>
                       </span>
                </p>
            </div>
            <img src={Option2}  className={'segment-image '} alt="logo" />
        </div>
    )
}

const SegmentSwapSellBuy = () => {
    const mobile = useIsMobile();
    return (
        <div className={'segment reversed'}>
            {!mobile && <img src={Option3}  style={{marginRight:"50px"}} className={'segment-image smaller'} alt="logo" />}

            <div className={'segment-content'}>
                <h1 className={'segment-title'}>Swap, Send, Buy, Sell</h1>
                <p className={'segment-description'}>
                    In the <span className={'trademark'}>BNF3 Wallet</span>, managing digital assets like buying, storing, sending, and swapping tokens is as effortless as a few taps.
                    With its intuitive interface and robust functionalities, users can seamlessly navigate through various actions with confidence.
                    Whether purchasing tokens, securely storing them, sending to friends or swapping for other assets, the <span className={"trademark"}>BNF3 wallet</span> streamlines the process, ensuring convenience without compromising security. Its user-friendly design empowers both newcomers and seasoned crypto enthusiasts to engage effortlessly in the dynamic world of digital assets, making managing tokens a smooth and accessible experience.
                </p>
            </div>
            {mobile && <img src={Option3}  className={'segment-image smaller'} alt="logo" />}
        </div>
    )
}
const SegmentOwnYourData = () => {
    return(
        <div className={'segment'}>
            <div className={'segment-content'}>
                <h1 className={'segment-title'}>Own Your Data</h1>
                <p className={'segment-description'}>
                    The <span className={'trademark'}>BNF3 Wallet</span> is designed with user privacy and security as its top priority. By utilizing blockchain technology, the wallet ensures that users have full control over their data and assets, providing a secure and transparent environment for managing digital assets. With its non-custodial approach, users can rest assured that their assets are safe and secure, free from any third-party intervention. The <span className={'trademark'}>BNF3 Wallet</span> empowers users to take ownership of their data, ensuring that their assets are protected and accessible only to them.
                </p>
            </div>
            <img src={SafeData}  className={'segment-image '} alt="logo" />
        </div>
    )
}

const SegmentFooter = () => {
    return (
        <div className={'footer'} id={'contact'}>
            <div className={'footer-content'}>

                <p>
                    <br/>
                    <span className={'l-bold'}>Join our community:</span>
                    <br/>
                    <a href={"https://discord.gg/EaaX4WjMzm"} target={"_blank"} title={"discord"}>
                        <img src={"https://img.icons8.com/color/48/000000/discord-logo.png"} alt={"discord"}
                             style={{width: "40px"}}/>
                    </a>
                    <a href={"https://twitter.com/bnf3wallet"} target={"_blank"} title={"twitter"}>
                        <img src={"https://img.icons8.com/color/48/000000/twitter--v1.png"} alt={"twitter"}
                             style={{width: "40px"}}/>
                    </a>
                    <a href={"https://www.instagram.com/bnf3wallet/"} target={"_blank"} title={"instagram"}>
                        <img src={"https://img.icons8.com/color/48/000000/instagram-new--v1.png"} alt={"instagram"}
                             style={{width: "40px"}}/>
                    </a>
                    <a href={"https://www.facebook.com/bnf3wallet"} target={"_blank"} title={"facebook"}>
                        <img src={"https://img.icons8.com/color/48/000000/facebook.png"} alt={"facebook"}
                             style={{width: "40px"}}/>
                    </a>

                </p>

                <h1 className={'footer-title'}>Contact Us</h1>
                <p className={'footer-description'}>
                    For any inquiries or feedback, feel free to reach out to us through the following channels:
                    <br/>
                    <br/>
                    Email: <span className={'l-bold'}>
                    <a href={"mailto:admin@bnf3.com"}>
                        admin@bnf3.com
                        </a>
                    </span>
                </p>
            </div>
        </div>
    )
}
export default App;
